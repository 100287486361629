import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { DesignBm } from '@cue/api';

export type NavigationSettings = {
  design: DesignBm;
};

export const NAVIGATION_SETTINGS = new InjectionToken('CUE_NAVIGATION_SETTINGS');

export function provideNavigationSettings(opt: { configurationFactory: () => NavigationSettings }) {
  return makeEnvironmentProviders([
    {
      provide: NAVIGATION_SETTINGS,
      useFactory: opt.configurationFactory,
      multi: false,
    },
  ]);
}
