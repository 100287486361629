import { inject, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PipesService } from './pipes.service';

@Pipe({
  name: 'dateTimeFormat',
  standalone: true,
})
export class DateTimeFormatPipe implements PipeTransform {
  private readonly pipesService = inject(PipesService);
  private readonly datePipe = inject(DatePipe);

  transform(value: string | number | Date): string {
    return (
      this.datePipe.transform(value, this.pipesService.dateFormat) + ' ' + this.datePipe.transform(value, this.pipesService.timeFormat)
    );
  }
}
