import { Pipe, PipeTransform } from '@angular/core';
import { translation } from '@assist/shared/translations';
import { ConfigService } from '@assist/shared/data';
import { AssistResourceBM } from '@cue/api';
import { TranslocoService } from '@ngneat/transloco';
import { localized } from '@cue/translate';

@Pipe({
  name: 'resourceTitle',
  standalone: true,
})
export class ResourceTitlePipe implements PipeTransform {
  translation = translation;

  constructor(
    private configService: ConfigService,
    private translocoService: TranslocoService,
  ) {}

  transform(value: AssistResourceBM): string {
    return `${localized(value.displayNameForApp, this.translocoService) ?? localized(value.displayName, this.translocoService)}
      (${localized(this.configService.getResourceTypeFromId(value.resourceTypeId).name, this.translocoService)})`;
  }
}
