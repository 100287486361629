import { Injectable } from '@angular/core';

export type DeviceId = string;
export type SessionId = string;

const DEVICE_ID_KEY = 'cueDevdeviceID';

@Injectable()
export class AnalyticsService {
  get deviceId(): DeviceId {
    let existingId = localStorage.getItem(DEVICE_ID_KEY);
    if (!existingId) {
      existingId = crypto.randomUUID();
      localStorage.setItem(DEVICE_ID_KEY, existingId);
    }
    return existingId;
  }

  _sessionId: SessionId | null = null;
  get sessionId(): SessionId {
    if (!this._sessionId) {
      this._sessionId = crypto.randomUUID();
    }
    return this._sessionId;
  }

  resetSession() {
    this._sessionId = null;
  }
}
