import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { ConfigService } from '@assist/shared/data';
import { CalendarAvailability } from '@cue/calendars';

@Pipe({
  name: 'availabilityColor',
  standalone: true,
})
export class AvailabilityColorPipe implements PipeTransform {
  constructor(private configService: ConfigService) {}

  transform(availability: CalendarAvailability, ...args: unknown[]): string {
    return this.configService.backgroundColor(availability);
  }
}
